import React from 'react';
import PropTypes from 'prop-types';

const ActionMessage = props => {
    return (
        <div style={{ marginTop: props.marginTop }}>
            <p style={{ color: props.color }}>{props.title}</p>
        </div>
    );
};

// Specifies the default values for props:
ActionMessage.defaultProps = {
    marginTop: '10px'
};

ActionMessage.propTypes = {
    marginTop: PropTypes.string
};
export default ActionMessage;