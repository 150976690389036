import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

/**
 * Utils
 */
import axios from 'utils/axios';


/**
 * Components
 */
import ConfirmModal from 'Components/ConfirmModal';


const PayMerchantPage = ({
    className,
    refreshLoggedinUser,
    loggedinUser,
    jwt,
}) => {
    /**
     * Constants
     */
    const modalRef = useRef();
    const initInstitutionState = {
        legal_name: 'Select Merchant',
        title: 'Please select a merchant',
        registrationUUID: null
    };

    /**
     * State
     */
    const [institution, setInstitution] = useState(initInstitutionState);
    const [amount, setAmount] = useState('');
    const [institutionSelectedIndex, setInstitutionSelectedIndex] = useState(false);
    const [newInstitutions, setNewInstitutions] = useState([]);

    const getSelectedIndex = (inputi) => {
        const dummyVariable = institutionSelectedIndex;
        console.log(dummyVariable);
        setInstitutionSelectedIndex(inputi);
    }
    /**
     * Handlers
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!institution) {
            toast.error('Please select an Institituion');
            return;
        }

        modalRef.current.open();
    };

    const handleConfirm = () => {
        const body = {
            id: loggedinUser.id,
            amount,
            merchant: institution
        };
        // alert(JSON.stringify(body));
        // return;
        axios.post('/pay/merchant', body, {
            headers: {
                Authorization: `JWT ${jwt}`
            }
        })
            .then((response) => {
                toast.success('Success! Payment of ' + amount + ' has been sent to the merchant.');
                setAmount('');
                refreshLoggedinUser();
            })
            .catch((err) => {
                toast.error(err.response.data);
                alert(err);
            });
    };

    const handleAmount = (_amount) => {
        console.log(amount, 'handleAmount change');
        setAmount(_amount);
        if (_amount > loggedinUser.balance) {
            toast.error('The amount that you are trying to pay is higher than your balance.');
        }
    };

    /**
     * Renderers
     */
    const renderInstitutions = (i) => newInstitutions.map((_institution, index) => <li key={_institution.registrationUUID} className={i === _institution ? 'selected' : ''}><a onClick={() => { setInstitution(_institution); getSelectedIndex(index); }} href="#/">{_institution.legal_name} - {_institution.balance}</a></li>);

    useEffect(() => {
        axios.get('/merchants')
            .then(response => {
                setNewInstitutions(response.data);
            })
            .catch(error => {
                console.log('There is an error');
                alert(error);
            });
    }, [institution]);

    return (
        <div className={`col-6 homepage ${className}`}>
            <div className="col-md-6">
                <form onSubmit={handleSubmit}>
                    <h2>Pay Merchant</h2>
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                            <span className="mr-2">Select a merchant</span>
                            <span className="caret ml-2" />
                        </button>
                        <ul className="dropdown-menu">
                            {renderInstitutions(institution)}
                        </ul>

                    </div>
                    <div className={`form-group fade-in-enter ${institution.registrationUUID && 'fade-in-enter-active'}`}>
                        <br />
                        <label htmlFor="amount">Enter payment amount</label>
                        <input
                            type="number"
                            required
                            className="form-control"
                            name="amount"
                            id="amount"
                            placeholder={'100 €'}
                            step="0.01"
                            value={amount}
                            onChange={(e) => handleAmount(e.currentTarget.value)}
                        />
                        <small id="emailHelp" className="form-text text-muted">Don't forget to leave a tip as well!</small>

                        <div className="mt-3">
                            <button type="submit" className="btn btn-primary d-block pt-2">
                                Pay Now
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-md-6">
                &nbsp;
            </div>

            <ConfirmModal
                ref={modalRef}
                id="payment_confirm_modal"
                confirm={handleConfirm}
                header="Confirm transfer"
                // body={`Please confirm payment of €${amount} to ${institution}`}
                body={(
                    <p>
                        Please confirm payment of
                        <b>
                            {' '}
                            €
                            {parseFloat(amount).toFixed(2)}
                            {' '}
                        </b>
                        to
                        <b>
                            {' '}
                            {institution.legal_name}
                        </b>
                    </p>
                )}
            />
        </div>
    );
};

PayMerchantPage.defaultProps = {
    className: '',
    loggedinUser: '',
    jwt: ''
};

PayMerchantPage.propTypes = {
    className: PropTypes.string,
    refreshLoggedinUser: PropTypes.func.isRequired,
    loggedinUser: PropTypes.object,
    jwt: PropTypes.string,
};

export default PayMerchantPage;
