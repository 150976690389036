import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'utils/axios';

/**
 * Static Files
 */
/**
 * Components
 */
const PasswordResetRequestPage = ({
    className,
    loginUser
}) => {
    /**
     * Constants
     */
    const history = useHistory();
    /**
     * State
     */
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);

    /**
     * Handlers
     */
    const { handleSubmit, register, errors } = useForm();
    const onSubmit = data => {
        axios.post('/password/reset/request',
            data
        )
            .then((response) => {
                // const resp = response.data;
                console.log('reset request successful...' + response.data);
                setSuccessMessage(response.data.message);
                setErrorMessage(false);
            }, (error) => {
                console.log('erroring...')
                console.log(error.response);
                setErrorMessage(error.response.data.message);
                // alert(JSON.stringify(error.response.data.message));
            });


    };

    useEffect(() => {
        console.log('useffect in password reset request page... ');

    });
    /**
     * Renderers
     */
    return (
        <div className={`col-6 sendpage ${className}`}>

            <div className="col-md-6 form-group fade-in-enter fade-in-enter-active">
                <h2>Reset password request</h2>
                {errorMessage &&
                    <h5 style={{ color: 'red', fontSize: '15px', fontWeight: 'bold' }}>{errorMessage}</h5>
                }
                {successMessage &&
                    <h5 style={{ color: 'blue', fontSize: '15px', fontWeight: 'bold' }}>{successMessage}</h5>
                }
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="pwd">Enter username or telephone:</label>
                        <input
                            name="username"
                            type="text"
                            className="form-control"
                            placeholder="Enter username or telephone number"
                            ref={register({
                                required: { value: true, message: 'Password field is required' },
                                minLength: { value: 7, message: 'Password must be at least 7 characters long' },
                            })}
                        />
                        {errors.newPassword && (
                            <p className="form-error">
                                {errors.newPassword.message}
                            </p>
                        )}
                    </div>

                    <button type="submit" className="btn btn-primary">Reset password</button>
                </form>
            </div>
        </div>
    );
};

PasswordResetRequestPage.defaultProps = {
    className: ''
};

PasswordResetRequestPage.propTypes = {
    className: PropTypes.string,
    loginUser: PropTypes.func.isRequired
};

export default PasswordResetRequestPage;
