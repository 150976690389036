import axios from 'axios';

const env = require('../config/env');
/**
 * Utils
 */


/**
 * Config
 */
// axios.defaults.baseURL = 'https://api.kosovo.vaiu.io/';
axios.defaults.baseURL = env.backendUrl;
axios.defaults.headers.common.Authorization = 'jwt ' + localStorage.getItem('jwt');
export default axios;
