// eslint-disable-for-this-file
import React from 'react';
import { Redirect } from 'react-router-dom';

// eslint-disable-next-line react/prefer-stateless-function
class ProtectedRoute extends React.Component {

    render() {
        const Component = this.props.component;
        let isAuthenticated = false;

        if (this.props.loggedIn) {
            isAuthenticated = true;
        }

        return isAuthenticated ? (
            <Component loggedInUser={this.props.loggedInUser} addTransaction={this.props.addTransaction} refreshLoggedinUser={this.props.refreshLoggedinUser} />
        ) : (
                // eslint-disable-next-line react/jsx-indent
                <Redirect to={{ pathname: '/loginUser' }} />
                // eslint-disable-next-line indent
            );
    }
}

export default ProtectedRoute;