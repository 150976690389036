import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'utils/axios';

/**
 * Static Files
 */

/**
 * Constants
 */





const HistoryPage = ({
    className,
}) => {

    /**
     * State
     */
    const [transactions, setTransactions] = useState([]);

    const getTransactionsFromVaiu = async () => {
        console.log('getting transactions here');
        await axios
            .post('/transactions')
            .then((response) => {
                console.log('transactions gotten: ' + response.data);
                const vaiuTransactions = response.data;
                // eslint-disable-next-line prefer-arrow-callback
                vaiuTransactions.sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                });
                setTransactions(vaiuTransactions);
            }).catch((error) => {
                console.log('Error getting transactions:' + error);
                alert(error);
            });
    };

    useEffect(() => {
        getTransactionsFromVaiu();
    }, []);

    return (
        <div className={`col-6 history ${className}`}>
            <div className="col-md-12">
                <h2>Transactions History</h2>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Token</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    {transactions.length > 0 && (
                        <tbody>
                            {transactions.map((item, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <tr key={`history_entry_${index}`}>
                                    <td>{index + 1}. {item.key}</td>
                                    <td>
                                        {' '}
                                        €
                                        {item.amount}
                                        {' '}
                                    </td>
                                    <td>{item.date}</td>
                                    <td>{item.status}</td>
                                </tr>
                            )
                            )}
                        </tbody>
                    )}

                </table>
            </div>
        </div >
    );
};

HistoryPage.defaultProps = {
    className: ''
};

HistoryPage.propTypes = {
    className: PropTypes.string
};

export default HistoryPage;
