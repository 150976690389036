import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
// import ActionMessage from 'Components/ActionMessage';
import { NavLink } from 'react-router-dom';
import {
    WhatsappShareButton,
    WhatsappIcon
} from 'react-share';
import axios from 'utils/axios';
/**
 * Static Files
 */
import { TRANSACTION_TYPES } from 'utils/functions';


/**
 * Components
 */
import ConfirmModal from 'Components/ConfirmModal';


const SendPage = ({
    className,
    addTransaction,
    loggedInUser,
    refreshLoggedinUser,
    jwt,
}) => {
    /**
     * Constants
     */

    const modalRef = useRef();

    /**
     * State
     */
    const [person] = useState('');
    const [amount, setAmount] = useState('');
    const [token, setToken] = useState('');
    const [displayToken, setDisplayToken] = useState(false);
    const [shareMessage, setShareMessage] = useState('');


    /**
     * Handlers
     */
    const handleSubmit = (e) => {
        e.preventDefault();


        // setToken(generateToken(6));
        modalRef.current.open();
    };

    const handleAmount = (_amount) => {
        if (_amount < loggedInUser.balance) {
            setAmount(_amount);
        } else {
            toast.error('The amount that you are trying to pay is higher than your balance.');
        }
    };

    const withdrawToken = async () => {
        await axios
            .post('/withdraw', { amount })
            .then((response) => {
                console.log(JSON.stringify(response.data));
                const vaiuToken = response.data.token;

                setToken(vaiuToken);
                setDisplayToken(true);
                setShareMessage('Hi there! Here is a VAIU token ' + vaiuToken + ' worth €' + amount);
                // eslint-disable-next-line react/jsx-wrap-multilines
                addTransaction({
                    description: 'Created token ' + vaiuToken,
                    recipient: person,
                    amount: parseFloat(amount).toFixed(2),
                    type: TRANSACTION_TYPES.TOKEN_CREDIT
                });
                setAmount('');
                refreshLoggedinUser();
            }).catch((error) => {
                console.log(error);
                alert(error);
            });
    };

    const handleConfirm = async () => {
        withdrawToken();
    };


    /**
     * Renderers
     */

    return (
        <div className={`col-6 sendpage ${className}`}>
            <div className="col-md-6">
                <form onSubmit={handleSubmit}>
                    <h2>
                        Withdraw
                    </h2>

                    {/* <div className={`form-group fade-in-enter ${person && 'fade-in-enter-active'}`}> */}
                    <div className="form-group fade-in-enter fade-in-enter-active">
                        <br />
                        <label htmlFor="amount">Enter amount</label>
                        <input
                            type="number"
                            required
                            className="form-control"
                            name="amount"
                            id="amount"
                            placeholder="00.00 €"
                            step="0.01"
                            value={amount}
                            onChange={(e) => handleAmount(e.currentTarget.value)}
                        />

                        <div className="mt-3">
                            <button type="submit" className="btn btn-primary d-block pt-2">
                                Get Token
                            </button>
                        </div>
                    </div>
                </form>
                {displayToken &&
                    (
                        <>
                            <p>
                                Congratulations! Your unique token is
                                {' '}
                                <b>
                                    {token}
                                    .
                                </b>
                                <br />
                                You can&nbsp;
                                <NavLink exact to="/history">view your tokens here</NavLink>
                                .
                            </p>
                            <p>
                                Share token:
                                <input
                                    type="text"
                                    className="form-control"
                                    name="shareMessage"
                                    id="shareMessage"
                                    value={shareMessage}
                                    onChange={(e) => setShareMessage(e.currentTarget.value)}
                                />
                            </p>
                            <p>
                                Send Vaiu token via
                                {' '}
                                <WhatsappShareButton url={shareMessage}>
                                    <WhatsappIcon size={32} round={true} />
                                </WhatsappShareButton>
                            </p>
                        </>
                    )
                }
            </div>
            <div className="col-md-6">
                &nbsp;
            </div>

            <ConfirmModal
                ref={modalRef}
                id="payment_confirm_modal"
                confirm={handleConfirm}
                header="Confirm withdrawal"
                body={(
                    <p>
                        You are about to withdraw
                        <b>
                            {' '}
                            €
                            {parseFloat(amount).toFixed(2)}
                            {' '}
                        </b>
                        in the form of a
                        {' token. '}
                    </p>
                )}
            />
        </div >
    );
};

SendPage.defaultProps = {
    className: '',
    loggedinUser: '',
    jwt: ''
};

SendPage.propTypes = {
    className: PropTypes.string,
    jwt: PropTypes.string,
    addTransaction: PropTypes.func.isRequired,
    loggedInUser: PropTypes.object,
    refreshLoggedinUser: PropTypes.func.isRequired
};

export default SendPage;
