import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import ActionMessage from 'Components/ActionMessage';
import PropTypes from 'prop-types';
import { TRANSACTION_TYPES } from 'utils/functions';

const DepositPage = ({
    className,
    addTransaction,
    loggedinUser,
    refreshLoggedinUser,
    jwt,
}) => {
    /**
     * Constants
     */

    /**
     * States
     */
    const [token, setToken] = useState('');
    const [displayMessage, setDisplayMessage] = useState({ title: '', color: '' });
    /**
     * Functions
     */
    const depositToken = async () => {
        await axios
            .post('/deposit', { token })
            .then((response) => {
                setDisplayMessage({ color: 'blue', title: 'Token successfully deposited.' });
                // eslint-disable-next-line react/jsx-wrap-multilines
                addTransaction({
                    description: 'Deposited token ' + token,
                    recipient: 'You',
                    amount: parseFloat(response.data.amount).toFixed(2),
                    type: TRANSACTION_TYPES.DEBIT
                });
                refreshLoggedinUser();
            }).catch((error) => {
                // alert(JSON.stringify(error.response));
                setDisplayMessage({ color: 'red', title: 'This token has already been redeemed or there is another problem. Deposit not implemented.' });
                // toast.error('This token has already been redeemed, or there is another problem. Deposit not implemented.');
            });
    };

    /**
     * Handlers
     */

    const handleSubmit = (e) => {
        e.preventDefault();
        if (token.length !== 6) {
            toast.error('Token must have exactly 6 characters.');
        } else {
            setDisplayMessage('');
            depositToken();
        }
        // setToken(generateToken(6));
        // modalRef.current.open();

    };

    const handleToken = (updated_token) => {
        if (updated_token.length <= 6) {
            setToken(updated_token.toUpperCase());
        } else {
            toast.error('Token cannot have more than 6 characters.');
        }
    };

    return (
        <div className={`col-6 sendpage ${className}`}>
            <div className="col-md-6">
                <form onSubmit={handleSubmit}>
                    <h2>
                        Deposit
                    </h2>
                    <div className="form-group fade-in-enter fade-in-enter-active">
                        <br />
                        <label htmlFor="amount">Enter token</label>
                        <input
                            type="text"
                            required
                            className="form-control"
                            name="amount"
                            id="amount"
                            placeholder=""
                            value={token}
                            onChange={(e) => handleToken(e.currentTarget.value)}
                        />

                        <div className="mt-3">
                            <button type="submit" className="btn btn-primary d-block pt-2">
                                Deposit Token to your account
                            </button>
                        </div>
                        <ActionMessage title={displayMessage.title} color={displayMessage.color} />

                    </div>
                </form>
            </div>
        </div>
    );
};



DepositPage.propTypes = {
    className: PropTypes.string,
    jwt: PropTypes.string,
    addTransaction: PropTypes.func.isRequired,
    loggedinUser: PropTypes.object,
    refreshLoggedinUser: PropTypes.func.isRequired,
};

export default DepositPage;
