
/**
 * Constants
 */
export const TRANSACTION_TYPES = {
    DEBIT: 'Debit', //  A debit entry in an account represents a transfer of value to that account
    CREDIT: 'Credit', // A credit entry represents a transfer from the account
    TOKEN_CREDIT: 'Token Credit' // A token credit entry represents a token based transfer from the account
};

export const generateToken = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const setBalanceStorage = (balance) => localStorage.setItem('balance', balance);
