import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
// import CountUp from 'react-countup';



/**
 * Components
 */


const MainLayout = ({
    className,
    children,
    isLoading,
    loggedIn,
    loggedInUser,
    logOutUser,
}) => {
    /**
     * States
     */
    // const [isLoadingChild, setIsLoadingChild] = useState(isLoading);
    // const loggedInUserParsed = JSON.parse(loggedInUser);
    /* if (loggedIn === 'true') {
        // alert('a');
        const loggedInUserParsed = JSON.parse(loggedInUser);
    } else {
        alert(JSON.stringify(loggedInUser));
        const loggedInUserParsed = { 'balance': '' };
    } */
    return (
        <div className={`mainlayout ${className}`}>
            <div className="container-fluid">
                <nav className="navbar navbar-default">
                    <div className="container">
                        <Link className="navbar-brand" to="/">
                            <img src="/images/logo_main_pk.png" alt="" />
                        </Link>
                        {loggedIn
                            ? (
                                <ul className="nav navbar-nav">
                                    <li><NavLink exact to="/payMerchant" activeClassName="nav-active">Pay Merchant</NavLink></li>
                                    <li><NavLink exact to="/payBill" activeClassName="nav-active">Pay Bill</NavLink></li>
                                    <li><NavLink exact to="/withdraw" activeClassName="nav-active">Withdraw</NavLink></li>
                                    <li><NavLink exact to="/deposit" activeClassName="nav-active">Deposit</NavLink></li>
                                    <li><NavLink exact to="/history" activeClassName="nav-active">View History</NavLink></li>
                                    <li><NavLink exact to="/profile/change/password" activeClassName="nav-active">Profile</NavLink></li>
                                    <li style={{ margin: '10px', marginTop: '15px' }}>
                                        <button
                                            onClick={() => logOutUser()}
                                            type="submit"
                                            style={{ backgroundColor: 'white' }}
                                        >
                                            Log Out
                                        </button>
                                    </li>
                                    <li style={{ margin: '10px', marginTop: '15px' }}>
                                        <span>
                                            <b>
                                                Balance:
                                                {JSON.stringify(loggedInUser.balance)}
                                                €
                                            </b>
                                        </span>
                                    </li>
                                </ul>
                            )
                            : (
                                <ul className="nav navbar-nav">
                                    {/* Public Routes */}
                                    <li><NavLink exact to="/loginUser" activeClassName="nav-active">Login User</NavLink></li>
                                    <li><NavLink exact to="/registerUser" activeClassName="nav-active">Register User</NavLink></li>
                                    <li><NavLink exact to="/loginMerchant" activeClassName="nav-active">Login Merchant</NavLink></li>
                                    <li><NavLink exact to="/registerMerchant" activeClassName="nav-active">Register Merchant</NavLink></li>
                                </ul>
                            )
                        }

                    </div>
                </nav>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {children}
                    </div>
                </div>
            </div>

            <div className="container-fluid footer" style={{ marginTop: '30px' }}>
                <div className="container">
                    <p style={{ fontWeight: 'bold' }}>
                        Powered by
                        <a href="https://app.vaiu.io/" target="_blank" rel="noopener noreferrer">
                            <img src="/images/vaiu-logo-small.png" alt="Logo" />
                        </a>
                    </p>
                </div>
            </div>
        </div >
    )
};

MainLayout.defaultProps = {
    className: '',
    loggedInUser: ''
};

MainLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    loggedInUser: PropTypes.object,
    logOutUser: PropTypes.func.isRequired,
};

export default withRouter(MainLayout);
