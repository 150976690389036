import React from 'react';
import PropTypes from 'prop-types';
import axios from 'utils/axios';
import { useForm } from 'react-hook-form';
/**
 * Static Files
 */


/**
 * Components
 */

const LoginMerchant = ({
    className,
    loginMerchant
}) => {
    /**
     * Constants
     */


    /**
     * State
     */


    /**
     * Handlers
     */
    const { handleSubmit, register, errors } = useForm();
    // const onSubmit = values => console.log(values);
    const onSubmit = data => {
        data.telephone = '156156165';
        // alert(JSON.stringify(data));
        axios.post('/merchant/login',
            data
        )
            .then((response) => {
                const jwt = response.data;
                console.log(response.data);
                console.log('execd successfully');
                loginMerchant(jwt);
            }, (error) => {
                console.log('Error:' + JSON.stringify(error.response));
                // alert(JSON.stringify(error.response.data));
            });
    };
    /**
     * Renderers
     */

    console.log(errors);
    return (
        <div className={`col-6 sendpage ${className}`}>

            <div className="col-md-6 form-group fade-in-enter fade-in-enter-active">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            name="username"
                            ref={register({
                                required: { value: true, message: 'This field is required' },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address'
                                }
                            })}
                        />
                        {errors.username && (
                            <p className="form-error">
                                {errors.username.message}
                            </p>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                            ref={register({
                                required: { value: true, message: 'This field is required' },
                                minLength: { value: 7, message: 'Password must be at least 7 characters long' },
                            })}
                        />
                        {errors.password && (
                            <p className="form-error">
                                {errors.password.message}
                            </p>
                        )}

                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    );
};

LoginMerchant.defaultProps = {
    className: ''
};

LoginMerchant.propTypes = {
    className: PropTypes.string,
    loginUser: PropTypes.func.isRequired
};

export default LoginMerchant;
