export const institutions = [
    {
        title: 'KEDS Electricity',
        description: 'Paid electricity bill',
        recipient: 'KEDS',
        totalDebt: 5,
        minAmount: 20
    },
    {
        title: 'KRU Prishtina Water',
        description: 'Paid water bill',
        recipient: 'KRU Prishtina',
        totalDebt: 50,
        minAmount: 1220
    },
    {
        title: 'NP Pastrimi Cleaning',
        description: 'Paid cleaning bill',
        recipient: 'NP PASTRIMI',
        totalDebt: 7,
        minAmount: 200
    },
    {
        title: 'Telkos ISP',
        description: 'Paid internet bill',
        recipient: 'Telkos',
        totalDebt: 15,
        minAmount: 35
    },
];
