import React from 'react';
import PropTypes from 'prop-types';


/**
 * Static Files
 */


/**
 * Components
 */


const ConfirmModal = React.forwardRef(({
    id, confirm, header, body
}, parentRef) => {
    // handlers
    const open = () => {
        // eslint-disable-next-line no-undef
        $('#payment_confirm_modal').modal();
    };

    if (parentRef) {
        parentRef.current = {
            open
        };
    }

    return (
        <div id={id} className="modal fade" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">{header}</h4>
                    </div>
                    <div className="modal-body">
                        {body}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-default btn-success"
                            data-dismiss="modal"
                            onClick={confirm}
                        >
                            Confirm
                        </button>
                        <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                    </div>
                </div>

            </div>
        </div>
    );
});

ConfirmModal.defaultProps = {
};

ConfirmModal.propTypes = {
    id: PropTypes.string.isRequired,
    confirm: PropTypes.func.isRequired,
    header: PropTypes.node.isRequired,
    body: PropTypes.node.isRequired
};

export default ConfirmModal;
