import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'utils/axios';
import { useForm } from "react-hook-form";
/**
 * Static Files
 */


/**
 * Components
 */



const RegisterMerchant = ({
    className
}) => {
    /**
     * Constants
     */


    /**
     * State
     */
    const [userIsRegistered, setUserIsRegistered] = useState(false);

    /**
     * Handlers
     */
    const { handleSubmit, register, errors } = useForm();
    // const onSubmit = values => console.log(values);
    const onSubmit = data => {
        // data.telephone = '156156165';
        // alert(JSON.stringify(data));
        axios.post('/merchant/signup',
            data
        )
            .then((response) => {
                console.log(response.data);
                console.log('execd successfully');
                setUserIsRegistered(true);
            }, (error) => {
                console.log(error);
                alert(JSON.stringify(error.response.data));
            });
    };
    /**
     * Renderers
     */

    console.log(errors);
    return (
        <div className={`col-6 sendpage ${className}`}>
            {!userIsRegistered
                ? (
                    <div className="col-md-6 form-group fade-in-enter fade-in-enter-active">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label htmlFor="legalNameLabel">Legal Name:</label>
                                <input
                                    name="legal_name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Company name"
                                    ref={register({
                                        required: { value: true, message: 'This field is required' },
                                        minLength: { value: 3, message: 'Legal name must be at least 3 characters long' },
                                    })}
                                />
                                {errors.legal_name && (
                                    <p className="form-error">
                                        {errors.legal_name.message}
                                    </p>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="legal_address">Business address:</label>
                                <input
                                    name="legal_address"
                                    type="text"
                                    className="form-control"
                                    placeholder="Company address"
                                    ref={register({
                                        required: { value: true, message: 'This field is required' },
                                        minLength: { value: 10, message: 'Business address must be at least 10 characters long' },
                                    })}
                                />
                                {errors.legal_address && (
                                    <p className="form-error">
                                        {errors.legal_address.message}
                                    </p>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="business_registration_number">VAT/TAX number:</label>
                                <input
                                    name="business_registration_number"
                                    type="text"
                                    className="form-control"
                                    placeholder="Unique number"
                                    ref={register({
                                        required: { value: true, message: 'This field is required' },
                                        minLength: { value: 8, message: 'VAT/TAX number must be at least 8 characters long' },
                                    })}
                                />
                                {errors.business_registration_number && (
                                    <p className="form-error">
                                        {errors.business_registration_number.message}
                                    </p>
                                )}
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    name="username"
                                    ref={register({
                                        required: { value: true, message: 'This field is required' },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                />
                                {errors.username && (
                                    <p className="form-error">
                                        {errors.username.message}
                                    </p>
                                )}
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Password:</label>
                                <input
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter password"
                                    ref={register({
                                        required: { value: true, message: 'This field is required' },
                                        minLength: { value: 7, message: 'Password must be at least 7 characters long' },
                                    })}
                                />
                                {errors.password && (
                                    <p className="form-error">
                                        {errors.password.message}
                                    </p>
                                )}
                                <div className="form-group">
                                    <label htmlFor="telephone">Telephone:</label>
                                    <input
                                        name="telephone"
                                        type="text"
                                        className="form-control"
                                        placeholder="Telephone"
                                        ref={register({
                                            required: { value: true, message: 'This field is required' },
                                            minLength: { value: 7, message: 'Telephone must be at least 7 characters long' },
                                        })}
                                    />
                                    {errors.telephone && (
                                        <p className="form-error">
                                            {errors.telephone.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                )
                :
                <p>Congratulations! You have successfully created a merchant account. In order to fully activate it, please check your e-mail and click the verification link.</p>
            }
        </div>
    );
};

RegisterMerchant.defaultProps = {
    className: ''
};

RegisterMerchant.propTypes = {
    className: PropTypes.string
};

export default RegisterMerchant;
