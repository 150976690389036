import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    Route,
    Redirect,
    useHistory
} from 'react-router-dom';
// import queryString from 'query-string'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'utils/axios';
/**
 * Pages
 */


import HomePage from 'Pages/PayBill';
import WithdrawPage from 'Pages/Withdraw';
import HistoryPage from 'Pages/HistoryPage';
import DepositPage from 'Pages/DepositPage';
import RegisterUser from 'Pages/RegisterUser';
import LoginUser from 'Pages/LoginUser';
import RegisterMerchant from 'Pages/RegisterMerchant';
import LoginMerchant from 'Pages/LoginMerchant';
import PayMerchantPage from 'Pages/PayMerchant';
import VerificationPage from 'Pages/VerificationPage';
import PasswordResetChangePage from 'Pages/PasswordResetChange';
import PasswordResetRequestPage from 'Pages/PasswordResetRequest';
import PaswordChangeProfilePage from 'Pages/PasswordChangeProfilePage';

import MainLayout from 'Layouts/MainLayout';
import ProtectedRoute from './Components/ProtectedRoute';
// import { setBalanceStorage } from 'utils/functions';

/**
 * Components
 */



const App = ({
    className
}) => {

    const [loggedInUser, setLoggedInUser] = useState({});
    // const [loggedInUser, setLoggedInUser] = useState(false);

    // const [jwt, setJwt] = useState(false);
    let jwtLocal = localStorage.getItem('jwt');
    // const [historyState, setHistoryState] = useState(getTransactionsHistory());
    const [isLoading] = useState(true);
    const history = useHistory();

    const refreshLoggedinUser = async () => {
        console.log('setting refreshLoggedinUser in user');
        jwtLocal = localStorage.getItem('jwt');
        if (!jwtLocal) {
            setLoggedInUser(false);
            console.log('!jwt exing');
            return;
        }
        console.log('this is exing...');
        const user = await axios.get('/user/')
            .catch((err) => {
                console.log('Erroring:' + err);
            });
        if (user) {
            console.log('Logging in user: ' + user.data);
            setLoggedInUser(user.data);
        } else {
            console.log('We are not logging in?')
        }

    };


    /**
     * Configurations
     */

    /**
     * Handlers
     */
    const handleLoginUser = async (jwtToken) => {
        console.log('handling user login');
        // setJwt(jwtToken.token);
        localStorage.setItem('jwt', jwtToken);
        axios.defaults.headers.common.Authorization = 'jwt ' + jwtToken;
        refreshLoggedinUser();
        history.push('/withdraw');
    };

    const handleLoginMerchant = async (jwtToken) => {
        console.log('handling merchant login: ' + jwtToken);
        // setJwt(jwtToken.token);
        localStorage.setItem('jwt', jwtToken);
        axios.defaults.headers.common.Authorization = 'jwt ' + jwtToken;

    };


    const handleLogoutUser = () => {
        console.log('Logging out user in App.js');
        // setJwt(undefined);
        localStorage.removeItem('jwt');
        axios.defaults.headers.common.Authorization = 'jwt None';
        setLoggedInUser(false);
        history.push('/loginUser');// do we really need this? perhaps pages themselves should show log in form instead of us pushing to it
    };


    const handleAddTransaction = (transaction) => {
        console.log('have no idea why this is here', transaction);
    };

    useEffect(() => {
        refreshLoggedinUser();
    }, [jwtLocal]);

    useEffect(() => {
        console.log('pushing...')
        // console.log(loggedInUser);
        // if (loggedInUser) {
        //    history.push('/withdraw');
        // }
    }, [loggedInUser]);

    return (
        <div className={`app ${className}`}>
            <MainLayout isLoading={isLoading} loggedIn={jwtLocal} loggedInUser={loggedInUser} logOutUser={handleLogoutUser}>
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={() => (
                            <Redirect to="/loginUser" />
                        )}
                    />
                    <ProtectedRoute exact path="/payMerchant" component={PayMerchantPage} loggedIn={jwtLocal} loggedInUser={loggedInUser} addTransaction={handleAddTransaction} refreshLoggedinUser={refreshLoggedinUser} />
                    <ProtectedRoute exact path="/payBill" component={HomePage} loggedIn={jwtLocal} loggedInUser={loggedInUser} addTransaction={handleAddTransaction} refreshLoggedinUser={refreshLoggedinUser} />
                    <ProtectedRoute exact path="/withdraw" component={WithdrawPage} loggedIn={jwtLocal} loggedInUser={loggedInUser} addTransaction={handleAddTransaction} refreshLoggedinUser={refreshLoggedinUser} />
                    <ProtectedRoute exact path="/deposit" component={DepositPage} loggedIn={jwtLocal} loggedInUser={loggedInUser} addTransaction={handleAddTransaction} refreshLoggedinUser={refreshLoggedinUser} />
                    <ProtectedRoute exact path="/history" component={HistoryPage} loggedIn={jwtLocal} loggedInUser={loggedInUser} addTransaction={handleAddTransaction} refreshLoggedinUser={refreshLoggedinUser} />
                    <ProtectedRoute exact path="/profile/change/password" component={PaswordChangeProfilePage} loggedIn={jwtLocal} loggedInUser={loggedInUser} addTransaction={handleAddTransaction} refreshLoggedinUser={refreshLoggedinUser} />
                    {/*
                    <Route exact path="/payMerchant">
                        <PayMerchantPage loggedinUser={loggedInUser} refreshLoggedinUser={refreshLoggedinUser} addTransaction={handleAddTransaction} />
                    </Route> 

                    <Route exact path="/payBill">
                        <HomePage loggedinUser={loggedInUser} addTransaction={handleAddTransaction} />
                    </Route>
                    <Route exact path="/withdraw">
                        <WithdrawPage loggedinUser={loggedInUser} refreshLoggedinUser={refreshLoggedinUser} addTransaction={handleAddTransaction} />
                    </Route>
                    <Route exact path="/deposit">
                        <DepositPage loggedinUser={loggedInUser} refreshLoggedinUser={refreshLoggedinUser} addTransaction={handleAddTransaction} />
                    </Route>
                    <Route exact path="/history">
                        <HistoryPage transactionHistory={historyState} resetApplication={handleResetApplication} />
                    </Route> */}

                    <Route exact path="/loginUser">
                        <LoginUser transactionHistory={[]} loginUser={handleLoginUser} />
                    </Route>
                    <Route exact path="/registerUser">
                        <RegisterUser transactionHistory={[]} loginUser={handleLoginUser} />
                    </Route>
                    <Route exact path="/registerMerchant">
                        <RegisterMerchant transactionHistory={[]} />
                    </Route>
                    <Route exact path="/LoginMerchant">
                        <LoginMerchant transactionHistory={[]} loginMerchant={handleLoginMerchant} />
                    </Route>
                    <Route exact path="/verify/:type/:id">
                        <VerificationPage transactionHistory={[]} loginUser={handleLoginUser} />
                    </Route>
                    <Route exact path="/reset/password/:jwt">
                        <PasswordResetChangePage transactionHistory={[]} loginUser={handleLoginUser} />
                    </Route>
                    <Route exact path="/reset/password/request/page">
                        <PasswordResetRequestPage transactionHistory={[]} loginUser={handleLoginUser} />
                    </Route>
                </Switch>
            </MainLayout>
            <ToastContainer position="top-left" />
        </div>
    );
};

App.defaultProps = {
    className: ''
};

App.propTypes = {
    className: PropTypes.string
};

export default App;
