import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

/**
 * Utils
 */
import { TRANSACTION_TYPES } from 'utils/functions';
import { institutions } from 'utils/institutions';

/**
 * Components
 */
import ConfirmModal from 'Components/ConfirmModal';


const HomePage = ({
    className,
    parentBalance,
    addTransaction
}) => {
    /**
     * Constants
     */
    const modalRef = useRef();
    const initInstitutionState = {
        title: 'Select Institution',
        description: null,
        recipient: null
    };

    /**
     * State
     */
    const [institution, setInstitution] = useState(initInstitutionState);
    const [amount, setAmount] = useState('');
    const [institutionSelectedIndex, setInstitutionSelectedIndex] = useState(false);
    let mutableInsitutions = institutions;

    const getSelectedIndex = (inputi) => {
        setInstitutionSelectedIndex(inputi);
    }
    /**
     * Handlers
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!institution.recipient) {
            toast.error('Please select an Insitituion');
            return;
        }

        modalRef.current.open();
    };

    const handleConfirm = () => {
        const successMessage = <p>
            Success!&nbsp;
                            <b>€{amount}</b>&nbsp;
                            have been paid to&nbsp;
                            <b>{institution.title}</b>.
                        </p>;
        const previousDebt = mutableInsitutions[institutionSelectedIndex].totalDebt;
        mutableInsitutions[institutionSelectedIndex].totalDebt = parseFloat(previousDebt) + parseFloat(amount);
        mutableInsitutions[institutionSelectedIndex].totalDebt = parseFloat(mutableInsitutions[institutionSelectedIndex].totalDebt).toFixed(2);
        toast.success(successMessage);
        addTransaction({
            description: institution.description,
            recipient: institution.recipient,
            amount: parseFloat(amount).toFixed(2),
            type: TRANSACTION_TYPES.CREDIT
        });

        setAmount('');
        setInstitution(initInstitutionState);
    };

    const handleAmount = (_amount) => {
        if (_amount < parentBalance) {
            setAmount(_amount);
        } else {
            toast.error('The amount that you are trying to pay is higher than your balance.');
        }
    };

    /**
     * Renderers
     */
    const renderInstitutions = () => mutableInsitutions.map((_institution, index) => <li key={_institution.recipient}><a onClick={() => { setInstitution(_institution); getSelectedIndex(index) }} href="#/">{_institution.title} {_institution.totalDebt}€</a></li>);

    return (
        <div className={`col-6 homepage ${className}`}>
            <div className="col-md-6">
                <form onSubmit={handleSubmit}>
                    <h2>Pay Bill</h2>
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                            <span className="mr-2">{institution.title} {institution.totalDebt}</span>
                            <span className="caret ml-2" />
                        </button>
                        <ul className="dropdown-menu">
                            {renderInstitutions()}
                        </ul>
                    </div>
                    <div className={`form-group fade-in-enter ${institution.recipient && 'fade-in-enter-active'}`}>
                        <br />
                        <label htmlFor="amount">Enter payment amount</label>
                        <input
                            type="number"
                            required
                            className="form-control"
                            name="amount"
                            id="amount"
                            placeholder={institution.minAmount + ' €'}
                            step="0.01"
                            value={amount}
                            onChange={(e) => handleAmount(e.currentTarget.value)}
                        />
                        <small id="emailHelp" className="form-text text-muted">Minimum suggested payment: <b>€{institution.minAmount}</b></small>

                        <div className="mt-3">
                            <button type="submit" className="btn btn-primary d-block pt-2">
                                Pay Now
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-md-6">
                &nbsp;
            </div>

            <ConfirmModal
                ref={modalRef}
                id="payment_confirm_modal"
                confirm={handleConfirm}
                header="Confirm transfer"
                // body={`Please confirm payment of €${amount} to ${institution}`}
                body={(
                    <p>
                        Please confirm payment of
                        <b>
                            {' '}
                            €
                            {parseFloat(amount).toFixed(2)}
                            {' '}
                        </b>
                        to
                        <b>
                            {' '}
                            {institution.title}
                        </b>
                    </p>
                )}
            />
        </div>
    );
};

HomePage.defaultProps = {
    className: '',
    parentBalance: 0
};

HomePage.propTypes = {
    className: PropTypes.string,
    parentBalance: PropTypes.number,
    addTransaction: PropTypes.func.isRequired
};

export default HomePage;
