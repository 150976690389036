import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'utils/axios';
import { useForm } from 'react-hook-form';
/**
 * Static Files
 */


/**
 * Components
 */


const LoginUser = ({
    className,
    loginUser
}) => {
    /**
     * Constants
     */


    /**
     * State
     */
    const [errorMessage, setErrorMessage] = useState(false);

    /**
     * Handlers
     */
    const { handleSubmit, register, errors } = useForm();
    const onSubmit = data => {
        axios.post('/login',
            data
        )
            .then((response) => {
                const resp = response.data;
                // console.log('Logging user in LoginUser.js...', jwt);
                loginUser(resp.token);
            }, (error) => {
                // console.log(error.response);
                setErrorMessage(error.response.data.message);
                // alert(JSON.stringify(error.response.data.message));
            });
    };

    /**
     * Renderers
     */
    return (
        <div className={`col-6 sendpage ${className}`}>
            <div className="col-md-6 form-group fade-in-enter fade-in-enter-active">
                {errorMessage &&
                    <h5 style={{ color: 'red', fontSize: '15px', fontWeight: 'bold' }}>{errorMessage}</h5>
                }
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="username">Email or Telephone:</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter email or telephone"
                            name="username"
                            ref={register({
                                required: { value: true, message: 'Email/telephone field is required' },
                                pattern: {
                                    required: { value: true, message: 'Email/telephone field is required' },
                                    minLength: { value: 9, message: 'Email/telephone must be at least 9 characters long' },
                                }
                            })}
                        />
                        {errors.username && (
                            <p className="form-error">
                                {errors.username.message}
                            </p>
                        )}
                    </div>


                    <div className="form-group">
                        <label htmlFor="pwd">Password:</label>
                        <input
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                            ref={register({
                                required: { value: true, message: 'Password field is required' },
                                minLength: { value: 7, message: 'Password must be at least 7 characters long' },
                            })}
                        />
                        {errors.password && (
                            <p className="form-error">
                                {errors.password.message}
                            </p>
                        )}
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                    <div className="form-group">
                        <p style={{ marginTop: '30px;' }}><a href="/reset/password/request/page">Forgot password?</a></p>
                    </div>
                </form>
            </div>
        </div >
    );
};

LoginUser.defaultProps = {
    className: ''
};

LoginUser.propTypes = {
    className: PropTypes.string,
    loginUser: PropTypes.func.isRequired
};

export default LoginUser;
