import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'utils/axios';
import { useForm } from 'react-hook-form';
/**
 * Static Files
 */


/**
 * Components
 */


const RegisterUser = ({
    className,
    loginUser
}) => {
    /**
     * Constants
     */


    /**
     * State
     */
    const [userIsRegistered, setUserIsRegistered] = useState(false);
    const [axiosError, setAxiosError] = useState(false);
    /**
     * Handlers
     */
    const { handleSubmit, register, errors } = useForm();
    const onSubmit = data => {
        // alert(JSON.stringify(data));
        axios.post('/signup',
            data
        )
            .then((response) => {
                console.log(response.data);
                console.log('execd successfully');
                setUserIsRegistered(true);
                // Check if user has verified via SMS. If yes, login user
                const timer = setInterval(() => {
                    console.log('interval executing');
                    axios.post('/checkIfUserHasVerifiedForBrowser/' + data.username,
                    )
                        .then((checkIfUserHasVerifiedForBrowserResponse) => {
                            // console.log(checkIfUserHasVerifiedForBrowserResponse.data);
                            clearInterval(timer);
                            const jwt = checkIfUserHasVerifiedForBrowserResponse.data.authToken;
                            console.log('checkIfUserHasVerifiedForBrowser execd successfully');
                            loginUser(jwt);
                        }, (error) => {
                            console.log('No success, do nothing...');
                        });
                }, 5000);
            }, (error) => {
                console.log(error);
                setAxiosError(error.response.data);
                // alert(JSON.stringify(error));
            });
    };

    /**
     * Renderers
     */
    return (
        <div className={`col-6 sendpage ${className}`}>
            {!userIsRegistered
                ? (
                    <div className="col-md-6 form-group fade-in-enter fade-in-enter-active">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {axiosError && (
                                <p className="form-error">
                                    {JSON.stringify(axiosError)}
                                </p>
                            )}
                            <div className="form-group">
                                <label htmlFor="username">Email:</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    name="username"
                                    ref={register({
                                        required: { value: true, message: 'Email field is required' },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                />
                                {errors.username && (
                                    <p className="form-error">
                                        {errors.username.message}
                                    </p>
                                )}
                            </div>

                            <div className="form-group">
                                <label htmlFor="pwd">Password:</label>
                                <input
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter password"
                                    ref={register({
                                        required: { value: true, message: 'Password field is required' },
                                        minLength: { value: 7, message: 'Password must be at least 7 characters long' },
                                    })}
                                />
                                {errors.password && (
                                    <p className="form-error">
                                        {errors.password.message}
                                    </p>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="telephone">Mobile Phone:</label>
                                <input
                                    name="telephone"
                                    type="text"
                                    className="form-control"
                                    placeholder="Your mobile phone"
                                    ref={register({
                                        required: { value: true, message: 'Telephone field is required' },
                                        minLength: { value: 9, message: 'Telephone must be at least 7 characters long' },
                                    })}
                                />
                                {errors.telephone && (
                                    <p className="form-error">
                                        {errors.telephone.message}
                                    </p>
                                )}
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                )
                :
                <p>Congratulations! You have successfully created an account. In order to fully activate it, please check the sms on your phone and click the verification link.</p>
            }
        </div>
    );
};

RegisterUser.defaultProps = {
    className: ''
};

RegisterUser.propTypes = {
    className: PropTypes.string,
    loginUser: PropTypes.func.isRequired
};

export default RegisterUser;
