import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import axios from 'utils/axios';

/**
 * Static Files
 */
/**
 * Components
 */
const VerificationPage = ({
    className,
    loginUser
}) => {
    /**
     * Constants
     */
    /**
     * State
     */
    const [message, setMessage] = useState('');
    /**
     * Handlers
     */
    const { type, id } = useParams();
    // const verificationUrl = `http://testing-vaiu-backend.antikorr.org/client/confirmation/${type}/${id}`;
    const verificationUrl = `/client/confirmation/${type}/${id}`;
    useEffect(() => {
        axios.get(verificationUrl)
            .then(response => {
                // console.log('Everything ok...');
                // alert(response.data.message);
                setMessage(response.data.message);
                setTimeout(() => {
                    loginUser();
                }, 3000);
            })
            .catch(error => {
                // console.log('There is an error');
                // console.log(error.response.data.message);
                // alert(error.response.data.message);
                setMessage(error.response.data.message);
            });

    });
    /**
     * Renderers
     */
    return (
        <div className={`col-6 sendpage ${className}`}>

            <div className="col-md-6 form-group fade-in-enter fade-in-enter-active">
                <h2>{message}</h2>
            </div>
        </div>
    );
};

VerificationPage.defaultProps = {
    className: ''
};

VerificationPage.propTypes = {
    className: PropTypes.string,
    loginUser: PropTypes.func.isRequired
};

export default VerificationPage;
