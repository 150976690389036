import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'utils/axios';

/**
 * Static Files
 */
/**
 * Components
 */
const PaswordChangeProfilePage = ({
    className,
    loginUser
}) => {
    /**
     * Constants
     */
    const history = useHistory();
    /**
     * State
     */
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);

    /**
     * Handlers
     */
    const { handleSubmit, register, errors, getValues
    } = useForm();
    const onSubmit = data => {
        axios.post('/password/change',
            data
            ,)
            .then((response) => {
                // const resp = response.data;
                console.log('Password change successful...');
                setSuccessMessage('Password change successful');
                setErrorMessage('');
                // clearInterval(timer1);
            }, (error) => {
                console.log(error.response);
                setErrorMessage(error.response.data);
                setSuccessMessage('');
                // alert(JSON.stringify(error.response.statusText));
            });


    };

    useEffect(() => {
        console.log('useffect in reset password page... ');

    });
    /**
     * Renderers
     */
    return (
        <div className={`col-6 sendpage ${className}`}>

            <div className="col-md-6 form-group fade-in-enter fade-in-enter-active">
                <h2>Profile Change Password</h2>
                {errorMessage &&
                    <h5 style={{ color: 'red', fontSize: '15px', fontWeight: 'bold' }}>{errorMessage}</h5>
                }
                {successMessage &&
                    <h5 style={{ color: 'blue', fontSize: '15px', fontWeight: 'bold' }}>{successMessage}</h5>
                }
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="pwd">Old password:</label>
                        <input
                            name="oldPassword"
                            type="password"
                            className="form-control"
                            placeholder=""
                            ref={register({
                                required: { value: true, message: 'Old password field is required' },
                                minLength: { value: 7, message: 'Password must be at least 7 characters long' },
                            })}
                        />
                        {errors.oldPassword && (
                            <p className="form-error">
                                {errors.oldPassword.message}
                            </p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="pwd">New password:</label>
                        <input
                            name="newPassword"
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                            ref={register({
                                required: { value: true, message: 'Password field is required' },
                                minLength: { value: 7, message: 'Password must be at least 7 characters long' },
                            })}
                        />
                        {errors.newPassword && (
                            <p className="form-error">
                                {errors.newPassword.message}
                            </p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="pwd">Confirm new password:</label>
                        <input
                            name="confirmNewPassword"
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                            ref={register({
                                required: { value: true, message: 'Confirm password field is required' },
                                minLength: { value: 7, message: 'Confirm password must be at least 7 characters long' },
                                validate: {
                                    passwordEqual: value => (value === getValues().newPassword) || 'Confirm password does not match with New password!',
                                }
                            })}
                        />
                        {errors.confirmNewPassword && (
                            <p className="form-error">
                                {errors.confirmNewPassword.message}
                            </p>
                        )}
                    </div>

                    <button type="submit" className="btn btn-primary">Reset password</button>
                </form>
            </div>
        </div>
    );
};

PaswordChangeProfilePage.defaultProps = {
    className: ''
};

PaswordChangeProfilePage.propTypes = {
    className: PropTypes.string,
    loginUser: PropTypes.func.isRequired
};

export default PaswordChangeProfilePage;
